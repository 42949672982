import React from "react";
import "./css/Games.css";
import bgpic from "./images/bgpatterngrey.png";
import icon1 from "./images/AppIcon1.png";
import icon2 from "./images/AppIcon2.png";
import media1 from "./images/slidingpuzzle.jpg";
import facebookbadge from "./images/facebook-badge.png";
import applebadge from "./images/apple-badge.png";
import googlebadge from "./images/google-play-badge.png";

export default class Games extends React.Component {
  render() {
    return (
      <div
        className="Games-container"
        id="games"
        name="games"
        style={{ backgroundImage: "url(" + bgpic + ")" }}
      >
        <div className="Games-offset">
          <div className="Games-content">
            <h1 className="Games-title">Games</h1>
            <div
              className="Games-Informations"
              style={{
                backgroundImage: "url(" + media1 + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="Game-name-container">
                <h2 className="Game-name" id="gamename">
                  Sliding Puzzles
                </h2>
              </div>
              <div className="Spacer"></div>
              <div className="Game-description-container">
                <p className="Game-description" id="gamedescription">
                  Play a classic game of sliding puzzle on one of the available
                  georgous pictures or use your own! Available on Facebook, and
                  soon on Android and iOS!
                </p>

                <div className="Game-badge-container">
                  <div className="Game-badge">
                    <a
                      href="https://fb.gg/play/sliding-puzzles"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={facebookbadge}
                        className="Game-badge-picture"
                        alt="facebookbadge"
                      ></img>
                    </a>
                  </div>
                  <div className="Game-badge">
                    <img
                      src={applebadge}
                      alt="applebadge"
                      className="Game-badge-picture"
                    ></img>
                  </div>
                  <div className="Game-badge">
                    <img
                      src={googlebadge}
                      className="Game-badge-picture"
                      alt="googlebadge"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="Games-Icons-Container">
              <ul className="Games-Icons-List">
                <li className="Games-Icon">
                  <img
                    src={icon1}
                    alt="gameicon"
                    className="Games-Icon-Image"
                  ></img>
                </li>
                <li className="Games-Icon">
                  <img
                    src={icon2}
                    alt="gameicon"
                    className="Games-Icon-Image"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
