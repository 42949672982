import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/* Vars used for Current Element (Scroll) Detection */

var anchors = ["featured", "games", "about", "contact"];
var anchorsPositions = [];
var scrollDirection = 0;
var currentElement = "featured";

/* Vars used for Swipe Detection */

var touchStartX;
var touchEndX;
var touchStartY;
var touchEndY;
var touchStartTime;
var touchEndTime;

window.onload = function() {
  ScrolltoCurrent();
};

/* Window resize event */

window.onresize = function(e) {
  /* Scrolling to current element */
  getanchorsPositions();
  ScrolltoCurrent();
  console.log("Window resized");
};

/* Function to scrollo to current element */

function ScrolltoCurrent() {
  window.location.href = "#" + anchors[anchors.indexOf(currentElement)];
}

/* Touch Start storage */

window.ontouchstart = function(e) {
  touchStartTime = new Date().getTime();
  touchStartX = e.changedTouches[0].pageX;
  touchStartY = e.changedTouches[0].pageY;
  console.log(
    "Touch Start: " + touchStartX + " " + touchStartY + " at " + touchStartTime
  );
};

/* Touch End storage */

window.ontouchend = function(e) {
  touchEndTime = new Date().getTime();
  touchEndX = e.changedTouches[0].pageX;
  touchEndY = e.changedTouches[0].pageY;
  touchScroll();
  console.log(
    "Touch End: " + touchEndX + " " + touchEndY + " at " + touchEndTime
  );
};

/* Touch Scroll function to go the next/previous anchor */

function touchScroll() {
  var touchDistance = Math.hypot(
    touchStartX - touchEndX,
    touchStartY - touchEndY
  );
  var touchTime = touchEndTime - touchStartTime;
  console.log("Touch Distance = " + touchDistance + " in " + touchTime);
  scrollDirection = touchStartY - touchEndY;
  if (touchDistance > 25 && touchTime < 1000) {
    if (
      scrollDirection > 0 &&
      anchors.indexOf(currentElement) < anchors.length - 1
    ) {
      window.location.href = "#" + anchors[anchors.indexOf(currentElement) + 1];
    } else if (scrollDirection < 0 && anchors.indexOf(currentElement) > 0) {
      window.location.href = "#" + anchors[anchors.indexOf(currentElement) - 1];
    }
  }
  console.log(scrollDirection);
}

/* Mouse Scroll event starts function to check which element is closest to the current page y offset */

window.onscroll = function() {
  ScrollCheck(window.pageYOffset);
};

/* Mouse wheel event function to go the next/previous anchor */

window.onwheel = function(event) {
  scrollDirection = event.deltaY;
  if (
    scrollDirection > 0 &&
    anchors.indexOf(currentElement) < anchors.length - 1
  ) {
    window.location.href = "#" + anchors[anchors.indexOf(currentElement) + 1];
    console.log(anchors.length);
  } else if (scrollDirection < 0 && anchors.indexOf(currentElement) > 0) {
    window.location.href = "#" + anchors[anchors.indexOf(currentElement) - 1];
  }
};

/* Function to gather the positions of each anchor (to be able to compare them to the current page y offset) */

function getanchorsPositions() {
  anchorsPositions = [];
  anchors.forEach(element =>
    anchorsPositions.push(document.getElementById(element).offsetTop)
  );
}

/* The main scroll check function to change the CSS of the navigation dots and header links according to the current element */

function ScrollCheck(position) {
  getanchorsPositions();

  anchors.forEach((element, index) => {
    if (
      position >= anchorsPositions[index] - 100 &&
      position <= anchorsPositions[index] + 100
    ) {
      currentElement = element;
    }
  });

  var links = document.getElementById("linkcontainer").childNodes;
  var dots = document.getElementById("dotcontainer").childNodes;

  links.forEach(element => {
    if (element.className === "App-link-active") {
      element.className = "App-link";
    }
  });

  dots.forEach(element => {
    if (element.className === "Nav-dot-active") {
      element.className = "Nav-dot";
    }
  });

  document.getElementById("link" + currentElement).className =
    "App-link-active";
  document.getElementById("dot" + currentElement).className = "Nav-dot-active";
}

/* Rendering the App */

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
