import React from "react";
import "./css/Footer.css";
import uparrow from "./images/top.png";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="Footer-container" id="games">
        <div className="Footer-content">
          <div className="Footer-legal">
            <div className="Brand">©2020 Screenshake Studios</div>
            <div className="Footer-legal-bis">
              <p>Terms & Conditions</p>
              <div className="Separator"></div>
              <p>
                <a href="Privacy.html" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
          <div>
            <a href="#featured">
              <img src={uparrow} className="Up-arrow" alt="top"></img>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
