import React from "react";
import "./css/Contact.css";
import logoFB from "./images/socialmedia/fb-logo-dark.png";
import logoTW from "./images/socialmedia/tw-logo-dark.png";
import logoIG from "./images/socialmedia/ig-logo-dark.png";
import logoYT from "./images/socialmedia/yt-logo-dark.png";
import bgpic from "./images/bgpatternblue.png";

function Contact() {
  return (
    <div
      className="Contact-container"
      id="contact"
      style={{ backgroundImage: "url(" + bgpic + ")" }}
    >
      <div className="Contact-offset">
        <div className="Contact-content">
          <h1 className="Contact-title">Contact</h1>
          <div className="Contact-maintext">
            {`Please reach out at the following address...`}
            <div>
              <span role="img" aria-label="loveletter">
                💌
              </span>{" "}
              <a
                href="mailto:contact@screenshake-studios.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@screenshake-studios.com
              </a>
            </div>
            {`...or via our social media pages!`}
            <ul className="Contact-social-media">
              <li>
                <a
                  href="https://www.facebook.com/ScreenShakeStudios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Contact-social-logo"
                    src={logoFB}
                    alt="FB"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ScreenshakeStu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Contact-social-logo"
                    src={logoTW}
                    alt="TW"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/screenshakestudios"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Contact-social-logo"
                    src={logoIG}
                    alt="IG"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UClAPe4XmZ05Vc-k0XUMH2GQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="Contact-social-logo"
                    src={logoYT}
                    alt="YT"
                  ></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
