import React from "react";
import "./css/About.css";
import portrait from "./images/autoportraitalpha.png";
import bgpic from "./images/bgpatternyellow.png";

function About() {
  return (
    <div
      className="About-container"
      style={{ backgroundImage: "url(" + bgpic + ")" }}
      id="about"
    >
      <div className="About-offset">
        <div className="About-content">
          <div className="TitleandPortrait">
            <img src={portrait} className="Portrait" alt="portrait"></img>
            <h1 className="About-title">About</h1>
          </div>
          <div className="About-text">
            <div className="Text-bubble">
              <div>
                <b>Screenshake Studios</b> is a French indie game studio started
                in 2020 by{" "}
                <b>
                  Emeric '
                  <a
                    href="https://twitter.com/TinyHatGames"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TinyHat
                  </a>
                  ' Vivet
                </b>{" "}
                as a solo project.
              </div>
              <p>
                We make juicy browser and mobile games that put the fun first
                and do our best to keep the clutter at bay. Puzzles, strategy,
                rpg, action, skill-based ... we love all game genres and can't
                wait to share our creations with you!
              </p>
              <p>
                Thanks for being part of our journey!{" "}
                <span role="img" aria-label="rocket">
                  🚀
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
