import React from "react";
import logo from "./images/logo.png";
import threelines from "./images/three_lines_white.png";
import threelinesclose from "./images/three_lines_white_close.png";
import "./css/Header.css";

var menuOpened = false;

function menuOpening() {
  var menu = document.getElementById("linkcontainer");

  if (menuOpened === true) {
    menu.style.display = "none";
    menuOpened = false;
    document.getElementById("appmenuicon").src = threelines;
  } else {
    menu.style.display = "flex";
    menuOpened = true;
    document.getElementById("appmenuicon").src = threelinesclose;
  }
}

export default class Header extends React.Component {
  render() {
    return (
      <div className="App-header" id="Header">
        <div className="Logo-container">
          <img alt="Logo" className="App-logo" src={logo}></img>
        </div>
        <div className="App-menu">
          <img
            alt="Menu"
            id="appmenuicon"
            className="App-menu-icon"
            src={threelines}
            onClick={menuOpening}
          ></img>
        </div>
        <div className="Link-container" id="linkcontainer">
          <div className="App-link-active" id="linkfeatured">
            <a href="#featured" className="App-link-text">
              FEATURED
            </a>
          </div>
          <div className="Link-separator"></div>
          <div className="App-link" id="linkgames">
            <a href="#games" className="App-link-text">
              GAMES
            </a>
          </div>
          <div className="Link-separator"></div>
          <div className="App-link" id="linkabout">
            <a href="#about" className="App-link-text">
              ABOUT
            </a>
          </div>
          <div className="Link-separator"></div>
          <div className="App-link" id="linkcontact">
            <a href="#contact" className="App-link-text">
              CONTACT
            </a>
          </div>
        </div>
      </div>
    );
  }
}
