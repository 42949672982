import React from "react";
import "./css/Navigation.css";

function Navigation() {
  return (
    <div className="Dots-container" id="dotcontainer">
      <div className="Nav-dot-active" id="dotfeatured">
        <a href="#featured">
          <span className="Dot"></span>
        </a>
      </div>
      <div className="Nav-dot" id="dotgames">
        <a href="#games">
          <span className="Dot"></span>
        </a>
      </div>
      <div className="Nav-dot" id="dotabout">
        <a href="#about">
          <span className="Dot"></span>
        </a>
      </div>
      <div className="Nav-dot" id="dotcontact">
        <a href="#contact">
          <span className="Dot"></span>
        </a>
      </div>
    </div>
  );
}

export default Navigation;
