import React from "react";
import "./css/Featured.css";
import { Slide } from "react-slideshow-image";
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  },
};

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide {...properties}>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${image1})` }}>
            <span>
              Welcome to the home of <b>Screenshake Studios!</b>
            </span>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${image2})` }}>
            <span>
              <b>Sliding Puzzles</b> - Coming soon to Facebook Instant Games,
              Android and IOS.
            </span>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default class Featured extends React.Component {
  render() {
    return (
      <div className="Featured-container" id="featured">
        <div className="Featured-content">{Slideshow()}</div>
      </div>
    );
  }
}
