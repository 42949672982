import React from "react";
import "./css/App.css";
import Navigation from "./Navigation";
import Featured from "./Featured";
import Games from "./Games";
import About from "./About";
import Contact from "./Contact";
import Header from "./Header";
import Footer from "./Footer";

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="Header-container">
          <Header />
        </div>
        <div className="Navigation-container">
          <Navigation />
        </div>
        <div className="App-content">
          <Featured />
          <Games />
          <About />
          <Contact />
        </div>
        <div className="">
          <Footer />
        </div>
      </div>
    );
  }
}
